.resume-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-header {
  width: 100%;
  background: #fafafa;
  border-bottom: 1px solid #e3ebe9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-print-box {
  width: 677px;
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.resume-print {
  cursor: pointer;
  width: 80px;
  height: 32px;
  font-size: 13px;
  border: 1px solid #e3ebe9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-print:hover {
  background-color: #E7080B;
  color: #fff;
}

@media print {
  .resume-print {
    display: none;
  }
}

.resume-header-box {
  padding: 50px 0;
  width: 677px;
  display: flex;
}

.resume-header-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
}

.resume-header-left img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.resume-header-right {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.resume-header-name {
  font-size: 26px;
  font-weight: 500;
}

.resume-header-info {
  font-size: 13px;
  color: #666;
}

.resume-body {
  padding: 50px 0;
  width: 677px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.resume-body-box {
  display: flex;
  flex-direction: column;
}

.resume-body-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.resume-body-title-decoration {
  width: 3px;
  height: 16px;
  background: #E7080B;
  margin-right: 5px;
}

.resume-body-content {
  font-size: 13px;
  color: #666;
}

.resume-body-items {
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.resume-body-item {
  display: flex;
  flex-direction: column;
}

.resume-body-item-up {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resume-body-item-left {
  display: flex;
  flex-direction: column;
}

.resume-body-item-name {
  color: #333;
  font-size: 15px;
  font-weight: 500;
}

.resume-body-item-info {
  margin-top: 5px;
  color: #333;
  font-size: 13px;
}

.resume-body-item-down {
  margin-top: 10px;
}
