.main-page {
  position: fixed;
  background-color: #1A1B1C;
  min-height: 100vh;
  width: 100%;
}

.main-header {
  display: flex;
  background-color: #1A1B1C;
  height: 64px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.main-merchantName {
  margin-left: 25px;
  color: #fff;
  font-weight: 500;
}

.main-box {
  margin-right: 25px;
  display: flex;
  align-items: center;
}

.main-addContent {
  margin-left: 10px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-func {
  margin-left: 30px;
  width: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-account {
  margin-left: 30px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-nav {
  position: absolute;
  width: 68px;
}

.main-outlet {
  position: absolute;
  left: 68px;
  top: 64px;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 10px 0 0 0;
}


/* 一级导航 */

.main-primaryNav-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.main-primaryNav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.main-primaryNav-iconActive {
  background-color: #545a65;
}

.main-primaryNav-icon:hover {
  background-color: #545a65;
}


/* 二级导航 */
.secondaryNav-page {
  display: flex;
  height: 100%;
}

.secondaryNav-container {
  padding: 10px;
  width: 250px;
  flex-shrink: 0;
  border-right: 1px solid #CED2D9;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.secondaryNav-headline {
  margin: 20px 0 5px 12px;
  font-weight: 500;
  font-size: 15px;
}

.secondaryNav-box {
  width: 100%;
  margin-top: 20px;
}

.secondaryNav-title {
  margin-left: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #262c39;
}

.secondaryNav-menu {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  border-radius: 3px;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.secondaryNav-menuActive {
  background-color: #F5F7FA;
}

.secondaryNav-menu:hover {
  background-color: #F5F7FA;
}

.secondaryNav-icon {
  float: left;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #525a66
}

.secondaryNav-name {
  /* font-weight: 500; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-primaryNav-divider {
  width: 32px;
  height: 1px;
  background-color: #545a65;
  margin: 20px 0;
}

/* 编辑商户资料 */

.main-setting-avatarUpload {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.main-setting-upload {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 通知 */

.main-notice-icon {
  position: relative;
  color: #fff;
  font-size: 18px;
}

.main-notice-reminder {
  position: absolute;
  left: 12px;
  top: 1px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #AE2A19;
}

.main-notice-box {
  margin-top: 3px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.main-notice-head {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.main-notice-title {
  font-size: 18px;
  font-weight: 600;
}

.main-notice-mark {
  cursor: pointer;
}

.main-notice-main {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 10px 0;
}

.main-notice-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.main-notice-detail {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 20px;
}

.main-notice-detail:hover {
  cursor: pointer;
  background-color: #F5F7FA;
}

.main-notice-opacity {
  opacity: 0.5;
}

.main-notice-detail-left {
  margin-top: 2px;
}

.main-notice-detail-center {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.main-notice-detail-title {
  font-weight: 500;
}

.main-notice-detail-content {
  color: #525A66;
}

.main-notice-detail-time {
  color: #525A66;
}

.main-notice-detail-right {
  margin-top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #AE2A19;
}

.main-notice-prompt {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-notice-empty {
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-notice-empty img{
  width: 120px;
  height: 120px
}

.main-notice-empty span{
  color: #525A66;
}
