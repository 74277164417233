.batch-container {
  flex: 1;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.batch-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.batch-func {
  height: 32px;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
  background-color: #4BE4B4;
}

.batch-tip {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.batch-button {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  height: 32px;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.batch-button:hover {
  background-color: #f5f7fa;
}
