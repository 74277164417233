.public-page {
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  height: 100%;
}

.public-container {
  margin-top: 30px;
  width: 1000px;
}

.public-header {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.public-title {
  margin-right: 30px;
  color: #090b0e;
  font-size: 24px;
  font-weight: 900;
}

.public-conditions {
  margin-top: 10px;
  margin-bottom: -10px;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
}

.public-conditions-row {
  display: flex;
  margin: 10px 0;
}

.public-condition {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.public-condition-label {
  width: 90px;
  text-align: right;
  color: #525A66;
}

.public-conditions-buttons {
  margin: 10px 0 5px 90px;
  display: flex;
  align-items: center;
  height: 32px;
}

.public-conditions-button1, .public-conditions-button2 {
  height: 100%;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.public-conditions-button1 {
  background-color: #4BE4B4;
  margin-right: 16px;
}

.public-conditions-button2 {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.public-conditions-button2:hover {
  background-color: #f5f7fa;
}

.public-main-style {
  padding-bottom: 30px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
}

/* 列表风格1 */
.public-main-style1 {
  margin-top: 30px;
  flex-wrap: wrap;
  width: 1000px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 19px;
}

.public-main-style1-add {
  width: 32%;
  height: 150px;
  border: 1px dashed #525A66;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s;
  background-color: #f5f7fa;
}

.public-main-style1-add:hover {
  background-color: #fff;
}

.public-main-style1-add:hover .zsxx-main-add-icon {
  background-color: #f5f7fa;
}

.public-main-style1-add-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: #eaecf2;
  border-radius: 5px;
  font-size: 15px;
  color: #525A66;
}

.public-main-style1-add-title {
  margin-left: 10px;
}

.public-main-style1-content {
  width: 32%;
  height: 150px;
  border: 1px solid #CED2D9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s;
}

.public-main-style1-content:hover {
  background-color: #f5f7fa;
}

.public-main-style1-content-title {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.public-main-style1-content-icon {
  margin-right: 10px;
}

.public-main-style1-content-name {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style1-content-delete {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: #525A66
}

.public-main-style1-content-delete:hover {
  background-color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.public-main-style1-content:hover .public-main-style1-content-delete {
  opacity: 1;
}

.public-main-style1-content-description {
  margin-top: 10px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 13px;
  line-height: 22px;
}

.public-main-style1-content-other {
  display: flex;
  justify-content: space-between;
}

.public-main-style1-content-other-left {
  font-size: 13px;
  color: #525A66;
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.public-main-style1-content-other-rights {
  display: flex;
  gap: 10px;
}

.public-main-style1-content-other-right {
  opacity: 0;
  font-size: 13px;
  color: #525A66;
  padding: 2px 5px;
  border: 1px solid #CED2D9;
  border-radius: 5px;
  background-color: #fff;
}

.public-main-style1-content:hover .public-main-style1-content-other-right {
  opacity: 1;
}

.public-main-style1-content-other-right:hover {
  color: #fff;
  border: 1px solid #525A66;
  background-color: #525A66;
}

.public-main-style1-auxiliaryBlank {
  width: 100%;
  height: 30px;
}

/* 列表风格2 */
.public-main-style2 {
  margin-top: 25px;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid #CED2D9;
  white-space: nowrap;
  overflow-x: auto;
}

.public-main-style2-item {
  width: 100%;
  border-collapse: collapse;
}

.public-main-style2-thead {
  height: 52px;
  background-color: #262c36;
  color: #fff;
  font-weight: 600;
}

.public-main-style2-tuwen {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.public-main-style2-tuwen img{
  border-radius: 5px;
  margin-right: 15px;
}

.public-main-style2-tuwen span{
  text-align: left;
  max-width: 360px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style2-title {
  padding-left: 20px;
  text-align: left;
  max-width: 360px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style2-title2 p{
  padding-left: 20px;
  text-align: left;
  max-width: 520px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style2-name {
  padding-left: 20px;
  text-align: left;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style2-name2 {
  text-align: left;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style2-viewBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.public-main-style2-view {
  cursor: pointer;
  width: 50px;
  height: 20px;
  border: 1px solid #090B0E;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.public-main-style2-view:hover {
  background: #090B0E;
  color: #fff;
}

.public-main-style2-operation_detail {
  text-align: left;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-main-style2-tbody {
  height: 56px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #CED2D9;
}

.public-main-style2-tbody:last-child {
  border-bottom: none;
}

.public-main-style2-tbody:hover {
  background-color: #f5f7fa;
}

.public-main-style2-tbody2 {
  height: 56px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #CED2D9;
}

.public-main-style2-tbody2:last-child {
  border-bottom: none;
}

.public-main-style2-tbody2:hover {
  background-color: #f5f7fa;
}

.public-main-style2-operation-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.public-main-style2-operation {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: #525A66;
  margin-right: 5px;
}

.public-main-style2-operation:hover {
  background-color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.public-main-style2-tbody:hover .public-main-style2-operation {
  opacity: 1;
}

.public-main-style2-tbodyEmpty {
  height: 52px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #CED2D9;
}

.public-main-style2-tfootNext {
  height: 52px;
  font-weight: 600;
  text-align: center;
  border-top: 1px solid #CED2D9;
  background-color: #f5f7fa;
}

.public-main-style2-tfootNext:hover {
  background-color: #CED2D9;
  cursor: pointer;
}

.public-main-style2-nextPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
}

.public-main-style2-tfootOver {
  height: 52px;
  text-align: center;
  border-top: 1px solid #CED2D9;
}


/* 列表风格3 */
.public-main-style3-wrapper {
  margin-top: 25px;
  width: 1000px;
  padding-bottom: 30px;
}

.public-main-style3-empty {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #CED2D9;
}

.public-main-style3-empty img{
  width: 200px;
  height: 200px;
  margin-top: 100px;
}

.public-main-style3-empty span{
  color: #717780;
}

.public-main-style3 {
  column-gap: 20px;
  column-count: 3;
}

.public-main-style3-box {
  border: 1px solid #ced2d9;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  break-inside: avoid-column;
  margin-bottom: 20px;
}

.public-main-style3-primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #090B0E;
  color: #fff;
  font-weight: 500;
  border-bottom: 1px solid #ced2d9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.public-main-style3-secondary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #ced2d9;
  cursor: pointer;
  color: #525A66;
}

.public-main-style3-add {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #F4F5F7;
}

.public-main-style3-title {
  display: flex;
  align-items: center;
}

.public-main-style3-primary p,
.public-main-style3-secondary p{
  margin: 0;
  font-weight: 500;
}

.public-main-style3-add p{
  margin: 0;
  margin-left: 10px;
  font-weight: 500;
}

.public-main-style3-primary img,
.public-main-style3-secondary img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
  padding: 2px;
}

.public-main-style3-secondary:hover,
.public-main-style3-add:hover {
  background-color: #F5F7FA;
}

.public-main-style3-primary:hover{
  background-color: #525A66;
}

.public-main-style3-primary-delete {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: #fff
}

.public-main-style3-primary-delete:hover {
  background-color: #fff;
  color: #090B0E;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.public-main-style3-primary:hover .public-main-style3-primary-delete {
  opacity: 1;
}

.public-main-style3-secondary-delete {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: #525A66
}

.public-main-style3-secondary-delete:hover {
  background-color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.public-main-style3-secondary:hover .public-main-style3-secondary-delete {
  opacity: 1;
}


/* 列表风格4 */
.public-main-style4 {
  margin-top: 30px;
  flex-wrap: wrap;
  width: 1000px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 19px;
}

.public-main-style4-items {
  width: 100%;
  height: auto;
  border: 1px solid #cfd2d9;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 30px;
}

.public-main-style4-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.public-main-style4-item-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.public-main-style4-item-title {
  font-weight: 500;
}

.public-main-style4-item-description {
  width: 660px;
  color: #262c36;
}

.public-main-style4-item-description pre{
  width: 600px;
  white-space: pre-wrap;
}

.public-main-style4-item-right {
  display: flex;
  gap: 15px;
}

.public-main-style4-item-buttonPrimary {
  cursor: pointer;
  width: 60px;
  height: 32px;
  background: #090B0E;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.public-main-style4-item-buttonSecondary {
  cursor: pointer;
  width: 60px;
  height: 32px;
  border: 1px solid #cfd2d9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.public-main-style4-item-buttonSecondary:hover {
  border-color: #525A66;
  color: #090B0E;
}

.public-main-style4-item-buttonPrimary:hover {
  background-color: #525A66;
}


/* 导入导出 */
.public-import-container {
  width: 100%;
  height: 460px;
  display: flex;
  justify-content: space-between;
}

.public-import-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-import-icon {
  font-size: 58px;
  margin-top: 38px;
}

.public-import-title {
  font-size: 18px;
  font-weight: 500;
}

.public-import-despcription {
  margin: 30px 0;
  width: 260px;
  text-align: center;
}

.public-import-button1, .public-import-button2 {
  margin-top: 20px;
  border-radius: 5px;
  padding: 0 20px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.public-import-button1 {
  background-color: #090B0E;
}

.public-import-button1 p{
  color: #fff;
}

.public-import-button1:hover {
  background-color: #525A66;
}

.public-import-button2 {
  border: 1px solid #525A66;
}

.public-import-button2:hover {
  background-color: #F5F7FA;
}

.public-import-button2 p{
  color: #090B0E;
}


/* 录取通知书打印 */
.public-print-container {
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-print-icon {
  font-size: 58px;
  margin-top: 38px;
}

.public-print-title {
  font-size: 14px;
  margin-bottom: 60px;
  color: #525A66;
}

.public-print-button1, .public-print-button2 {
  margin-top: 20px;
  border-radius: 5px;
  padding: 0 20px;
  width: 260px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.public-print-button1 {
  background-color: #090B0E;
}

.public-print-button1 p{
  color: #fff;
}

.public-print-button1:hover {
  background-color: #525A66;
}

.public-print-button2 {
  border: 1px solid #525A66;
}

.public-print-button2:hover {
  background-color: #F5F7FA;
}

.public-print-button2 p{
  color: #090B0E;
}


/* 录取通知书模版 */
.public-template-container {
  height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-template-icon {
  font-size: 58px;
  margin-top: 38px;
}

.public-template-title {
  font-size: 18px;
  font-weight: 500;
}

.public-template-despcription {
  margin: 30px 0;
  width: 260px;
  text-align: center;
}

.public-template-button1, .public-template-button2 {
  margin-top: 20px;
  border-radius: 5px;
  padding: 0 20px;
  width: 160px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.public-template-button1 {
  background-color: #090B0E;
}

.public-template-button1 p{
  color: #fff;
}

.public-template-button1:hover {
  background-color: #525A66;
}

.public-template-button2 {
  border: 1px solid #525A66;
}

.public-template-button2:hover {
  background-color: #F5F7FA;
}

.public-template-button2 p{
  color: #090B0E;
}


/* 数据详情 */
.public-datail-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.public-datail-item {
  display: flex;
  width: 100%;
}

.public-datail-label {
  width: 150px;
  color: #525A66;
}

.public-datail-contents {
  width: 440px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.public-datail-content {
  width: 440px;
  font-weight: 500;
}

.public-datail-photo {
  width: 440px;
  display: flex;
  gap: 10px;
}

.public-datail-pdf {
  width: 80px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.public-datail-status {
  font-weight: 500;
  height: 28px;
  display: flex;
  align-items: center;
}

.public-datail-button {
  cursor: pointer;
  margin-left: 20px;
  width: 60px;
  height: 28px;
  background-color: #4BE4B4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.public-datail-button2 {
  cursor: pointer;
  width: 60px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #525A66;
  border-radius: 5px;
}

.public-download-button {
  margin-top: 20px;
  cursor: pointer;
  width: 100px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #525A66;
  border-radius: 5px;
}

.public-download-button:hover {
  background: #525A66;
  color: #fff;
}

.public-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 40px;
}

.public-filter-button {
  margin-top: 10px;
  width: 80px;
  height: 36px;
  border-radius: 3px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: background-color 0.3s ease;
  background-color: #fff;
  border: 2px solid #d9d9d9;
}

.public-filter-button:hover {
  background-color: #f5f7fa;
}

.public-filter-datePicker {
  width: 200px;
  height: 100%;
}