.people-personal {
  display: flex;
  font-weight: 500;
}

.people-box {
  margin-left: 10px;
}

.people-nickname {
  color: #090b0e;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.people-username {
  font-size: 13px;
  color: #717780;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
