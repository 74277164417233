.dashboard-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dashboard-container {
  margin-top: 22px;
  width: 1300px;
  display: flex;
  justify-content: space-between;
}

.dashboard-container-left {
  width: 900px;
  display: flex;
  flex-direction: column;
}

.dashboard-container-left-greeting {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #cfd2d9;
  height: 46px;
}

.dashboard-container-left-overview {
  margin-top: 40px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.dashboard-container-left-title {
  margin-left: 3px;
  font-size: 16px;
  font-weight: 500;
}

.dashboard-container-left-content {
  margin-top: 10px;
  width: 100%;
  height:500px;
  border: 1px solid #cfd2d9;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-container-left-content img{
  width: 260px;
  height: auto;
  margin-top: 60px;
}

.dashboard-container-left-content span{
  font-weight: 500;
  font-size: 16px;
}



.dashboard-container-right {
  width: 360px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}

.dashboard-container-right-dynamic {
  width: 100%;
  height: 280px;
  border: 1px solid #cfd2d9;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
}

.dashboard-container-right-news {
  width: 100%;
  height: 280px;
  border: 1px dashed #cfd2d9;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
}

.dashboard-container-right-title {
  font-size: 16px;
  font-weight: 500;
}

.dashboard-container-right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-container-right-content img{
  width: 160px;
  height: auto;
  opacity: 0.8;
}

.dashboard-container-right-content span{
  color: #717780;
}
