.add-addContent {
  padding: 0 18px;
  height: 36px;
  background-color: #4BE4B4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
}

.add-addContent p{
  margin-left: 3px;
  font-weight: 600;
}
