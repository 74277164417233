.error-page {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.error-container {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 320px;
  height: 320px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-figure {
  width: 320px;
  height: 320px;
}

.error-title {
  width: 320px;
  text-align: center;
  font-size: 14px;
  color: #525a66;
}
