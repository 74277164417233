.select-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0;
}

.select-name {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.select-container label {
  font-weight: 600;
  margin-bottom: 8px;
  color: #525a66;
}

.select-noData {
  padding: 10px;
  color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select-noData p{
  margin-top: 5px;
}
