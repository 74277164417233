.form-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: .9375rem;
}

.form-box label{
  font-weight: 600;
  margin-bottom: .3125rem;
  color: #525a66;
}

.form-errormsg {
  display: flex;
  align-items: center;
  margin: .1875rem 0 0 .1875rem;
}

.form-errormsg p {
  color: #ae2a19;
  font-size: .8125rem;
  margin: 0;
}

.form-errormsg span {
  margin-right: .3125rem;
  color: #ae2a19;
  font-size: .8125rem;
}

.form-button {
  height: 25rem;
}

.form-richText {
  height: auto;
  margin: .375rem 0 1.125rem 0;
}

.form-richText-label{
  font-weight: 600;
  margin-bottom: .3125rem;
  color: #525a66;
}

.form-upload-box {
  display: flex;
  align-items: center;
}

.form-upload-img {
  border-radius: .1875rem;
  border: .125rem solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-upload-img img{
  border-radius: .1875rem;
}

.form-upload {
  margin-left: .625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-upload-video {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem;
  color: #525a66;
}

.form-upload-docBox {
  flex-direction: column;
}

.form-upload-doc {
  border-radius: 3.125rem;
  border: .125rem solid #d9d9d9;
  width: 10rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.form-upload-doc:hover {
  background-color: #F4F5F7;
}

.form-upload-doc span{
  margin-left: .625rem;
}

.form-upload-doc-display {
  color: #525A66;
  margin-top: .625rem;
  margin-left: .625rem;
  display: flex;
  align-items: center;
}

.form-upload-doc-display span{
  margin-left: .625rem;
}

.form-upload-doc-del {
  opacity: 0;
  cursor: pointer;
  margin-left: .625rem;
  padding: 0 .3125rem;
}

.form-upload-doc-del:hover {
  background-color: #F4F5F7;
}

.form-upload-doc-display:hover .form-upload-doc-del {
  opacity: 1;
}

.form-datePickers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}


/* ------------------------------------------------------------------------------------------------------------------------ */

/* antd输入框 */
.ant-picker,
.ant-picker:hover,
.ant-input,
.ant-input:hover,
.ant-input:not(:hover),
.ant-input:focus,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: .125rem solid #d9d9d9;
  border-radius: .1875rem;
  box-shadow: none
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input ,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  border-color: #AE2A19;
}

/* antd按钮 */
.ant-btn-primary:not(:disabled) {
  background-color: #090B0E;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #525A66;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #525A66;
  color: #090B0E;
}

.ant-btn-default.ant-btn-dangerous,
.ant-btn-default.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #AE2A19;
  color: #AE2A19;
}

/* antd下拉菜单 */
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  margin-top: .1875rem;
  max-height: 37.5rem;
  overflow-y: auto;
  border-radius: .1875rem;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: .4375rem .625rem .4375rem .625rem !important;
  margin: .3125rem 0rem .3125rem 0rem !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled.ant-dropdown-menu-item-only-child,
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled.ant-dropdown-menu-item-only-child:hover {
  cursor: default
}

/* antd弹窗 */
.ant-modal-title {
  font-size: 1.125rem !important;
  padding-bottom: .625rem !important;
}

/* antd选择器 */
/* .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
} */

.ant-select-selector {
  /* border-color: #F4F5F7 !important; */
  border-width: .125rem !important;
  border-color: #d9d9d9 !important;
  border-radius: .125rem !important;
  /* display: flex !important;
  align-items: center !important;
  background-color: #F4F5F7 !important; */
}

/* .ant-select,
.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
} */

/* antd Tooltip文字提示 */
.ant-tooltip-inner {
  font-size: .75rem;
  font-weight: 500;
}

/* ------------------------------------------------------------------------------------------------------------------------ */

/* quill富文本 */
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-width: .125rem !important;
  border-color: #d9d9d9 !important;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: .1875rem;
  border-top-left-radius: .1875rem;
}

.ql-container.ql-snow {
  border-bottom-right-radius: .1875rem;
  border-bottom-left-radius: .1875rem;
}

.ql-container {
  min-height: 5rem;
  max-height: 31.25rem;
}

.ql-editor {
  min-height: 5rem;
  max-height: 31.25rem;
  overflow: auto;
}

.ql-editor p {
  font-size: .875rem !important;
}

.ql-indent-1 {
  padding-left: 0rem !important;
  text-indent: 2em !important;
}


/* ------------------------------------------------------------------------------------------------------------------------ */

/* 收集表单详情 */
.forms-collect-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  min-height: 450px;
} 

.forms-collect-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #F4F5F7;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
}

.forms-collect-button:hover {
  background-color: #d9d9d9;
}

.forms-collect-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  background-color: #F4F5F7;
  border: 2px solid #F4F5F7;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}

.forms-collect-name:hover {
  border: 2px solid #d9d9d9;
}

.forms-collect-title {
  font-weight: 600;
  font-size: 18px;
}

.forms-collect-description {
  font-size: 12px;
  color: #525A66;
}

.forms-collect-item {
  width: 100%;
  display: flex;
  gap: 5px;
  cursor: pointer;
  background-color: #F4F5F7;
  border: 2px solid #F4F5F7;
  padding: 15px 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.forms-collect-item:hover {
  border: 2px solid #d9d9d9;
}

.forms-collect-item-required {
  font-weight: 600;
  font-size: 16px;
  width: 3px;
  color: #AE2A19;
}

.forms-collect-item-serialNumber {
  font-weight: 600;
  font-size: 16px;
  width: 30px;
}

.forms-collect-item-question {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.forms-collect-item-upload {
  width: 180px;
  height: 75px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.forms-collect-item-upload p{
  font-size: 12px;
}

.forms-collect-item-title {
  display: flex;
  justify-content: space-between;
}

.forms-collect-item-title span{
  font-weight: 600;
  flex: 1;
}

.forms-collect-item-delete {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}

.forms-collect-item:hover .forms-collect-item-delete {
  opacity: 1;
}

.forms-collect-item-delete:hover {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 5px;
}

.forms-collect-item-description {
  color: #525A66;
}

.forms-collect-item-select {
  display: flex;
  gap: 10px;
  align-items: center;
}

.forms-collect-add-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forms-collect-add-option {
  display: flex;
  gap: 10px;
}

.forms-collect-add-option input{
  flex: 1;
  padding-left: 10px;
}

.forms-collect-add-option-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 16px;
  color: #525A66;
  cursor: pointer;
}

.forms-collect-add-option-delete:hover {
  background-color: #F4F5F7;
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.forms-collect-add-options button{
  width: 80px;
  height: 30px;
  border: 1px solid #525A66;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.forms-collect-add-options button:hover{
  background-color: #F4F5F7;
}
