.filter-container {
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-filterContainer {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.filter-addButton, .filter-addButton2, .filter-addButton3, .filter-condition {
  height: 32px;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.filter-condition:hover {
  background-color: #f5f7fa;
}

.filter-addButton {
  background-color: #4BE4B4;
  margin-right: 10px;
}

.filter-addButton2 {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.filter-addButton2:hover {
  background-color: #f5f7fa;
}

.filter-addButton3 {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.filter-addButton3:hover {
  background-color: #f5f7fa;
}

.filter-dropdown {
  width: 200px;
  margin-top: 3px;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px;
}
