.auth-page {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-logo {
  position: absolute;
  top: 0;
  left: 15px;
  cursor: pointer;
}

.auth-logo img {
  height: 30px;
  width: auto;
}

.auth-logo p{
  font-size: 20px;
  font-weight: 600;
}

.auth-container {
  width: 350px;
  padding: 38px;
  background-color: #FAFCFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.auth-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.auth-header .auth-title {
  font-size: 22px;
  font-weight: 600;
}

.auth-header .auth-description {
  color: #525A66;
  width: 300px;
  text-align: center;
  margin-top: 20px;
}

.auth-loginForm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.auth-forgot {
  width: 100%;
  display: flex;
  align-items: center;
}

.auth-forgotLink {
  font-size: 13px;
  color: #005dff;
}

.auth-toRegister {
  text-align: center;
  color: #525A66;
  margin-top: 15px;
}

.auth-toRegisterLink {
  color: #005dff;
  font-weight: 600;
  text-decoration: none;
}



.auth-otherLogin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
}

.auth-otherLine {
  flex: 1;
  height: 1px;
  background-color: #CED2D9;
}

.auth-otherText {
  padding: 0 15px;
  color: #525A66;
}

.auth-otherLoginBlock {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  height: 40px;
  border: 1px #CED2D9 solid;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.auth-otherLoginBlock:hover {
  background-color: #f2f2f2;
}

.auth-otherLoginBlock span{
  font-size: 20px;
}

.auth-otherLoginBlock img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.auth-otherLoginBlock p{
  font-size: 13px;
  color: #525A66;
  margin-left: 5px;
}

.auth-statement {
  font-size: 13px;
  color: #525A66;
  margin-top: 10px;
}

.signature {
  font-size: 13px;
  color: #525A66;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
}
