.facebody-category-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.facebody-category-address-qrcode {
  width: 220px;
  height: 220px;
}

.facebody-category-address-qrcode img{
  width: 100%;
  height: 100%;
}

.facebody-category-address-buttonBox {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.facebody-category-address-button1, .facebody-category-address-button2 {
  border-radius: 5px;
  padding: 0 20px;
  width: 60px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  grid-gap: 5px;
  transition: background-color 0.3s ease;
}

.facebody-category-address-button1 {
  background-color: #090B0E;
}

.facebody-category-address-button1 p{
  color: #fff;
}

.facebody-category-address-button1:hover {
  background-color: #525A66;
}

.facebody-category-address-button2 {
  border: 1px solid #525A66;
}

.facebody-category-address-button2:hover {
  background-color: #F5F7FA;
}

.facebody-category-address-button2 p{
  color: #090B0E;
}
